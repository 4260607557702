<template>
  <div class="step-container">
    <div>
      <div class="text-center">
        <div class="body4-bold primary">3/3단계</div>
        <div class="pc">
          <div class="h6 main margin-top-12">결제정보 완성하기</div>
          <div class="body4 sub3 margin-top-12">결제가 완료되면 <br class="v-mobile">바로 내 서비스 개설이 시작됩니다.<br>
            추가 설정도 광고도 없이 14일 무료체험으로 내 서비스를 가지세요
          </div>
        </div>
        <div class="mobile tablet">
          <div class="mo-section-title-bold main margin-top-8 text-center">결제정보 등록하고<br>14일 무료체험 혜택 받으세요.</div>
          <div class="body4 sub3 text-center margin-top-8">결제가 완료되면 바로 멤버십이 등록되고<br>무료 14일 체험이 시작됩니다!<br>추가 설정도 광고도 없이 내 서비스 개설</div>
        </div>
      </div>

      <!-- 결제 모듈 -->
      <div style="max-width:600px;margin:60px auto 0 auto">

        <div class="pc box-account margin-bottom-12">
          <template v-for="(option, option_idx) in order.order_product.order_product_option">
            <div class="flex-between" :key="`option-${option_idx}`" v-if="option.option_type===0">
              <div class="body4-medium main">{{ optionName(option) }}<span v-if="option_idx===0"> 멤버십</span></div>
              <div>
                <span class="body4-bold">{{ optionPrice(option)|currencyNum }}</span>
                <span class="body4 sub">원</span>
                <span class="body4 sub3">/월</span>
              </div>
            </div>
          </template>
          <div class="divider"></div>
          <div class="flex-between">
            <div class="body4-medium main">{{ hostingName }}</div>
            <div>
              <span class="body4-bold">{{ order.hosting.price | currencyNum }}</span>
              <span class="body4 sub">원</span>
              <span class=" sub3">/월</span>
            </div>
          </div>
          <div class="divider"></div>
          <div class="flex-between">
            <div class="body4-medium main">부가세 (10%)</div>
            <div>
              <span class="body4-bold">{{ vat | currencyNum }}</span>
              <span class="body4 sub">원</span>
            </div>
          </div>

          <div class="divider-main"></div>
          <div class="flex-between">
            <div class="body4-medium main">총 금액 <span class="body5 sub3"
                                                      style="margin-left:4px">(청구 시작일 : {{ payDay }})</span></div>
            <div>
              <span class="body4-bold">{{ order.total_price | currencyNum }}</span>
              <span class="body4 sub">원</span>
              <span class=" sub3">/월</span>
            </div>
          </div>
        </div>

        <div class="mobile tablet box-account-mo margin-bottom-12">
          <template v-for="(option, option_idx) in order.order_product.order_product_option">
            <div class="flex-between" :key="`option-${option_idx}`" v-if="option.option_type===0">
              <div class="body5-medium main">{{ optionName(option) }}<span v-if="option_idx===0"> 멤버십</span></div>
              <div>
                <span class="body5-bold">{{ optionPrice(option)|currencyNum }}</span>
                <span class="body5 sub">원</span>
                <span class="body5 sub3">/월</span>
              </div>
            </div>
          </template>
          <div class="divider"></div>
          <div class="flex-between">
            <div class="body5-medium main">{{ hostingName }}</div>
            <div>
              <span class="body5-bold">{{ order.hosting.price | currencyNum }}</span>
              <span class="body5 sub">원</span>
              <span class="body5 sub3">/월</span>
            </div>
          </div>
          <div class="divider"></div>
          <div class="flex-between">
            <div class="body5-medium main">부가세 (10%)</div>
            <div>
              <span class="body5-bold">{{ vat | currencyNum }}</span>
              <span class="body5 sub">원</span>
            </div>
          </div>

          <div class="divider-main"></div>
          <div class="flex-between">
            <div class="body6-medium main">총 금액 <br><span class="body6 sub3">(청구 시작일 : {{ payDay }})</span></div>
            <div>
              <span class="body5-bold">{{ order.total_price | currencyNum }}</span>
              <span class="body5 sub">원</span>
              <span class="body5 sub3">/월</span>
            </div>
          </div>
        </div>

        <div class="box-free">
          <div class="subtitle6">오늘 결제 금액</div>
          <div class="h6">0<span class="body2">원</span></div>
        </div>

        <ul style="margin-top:20px;padding:0 20px 0 28px" class="body5 sub2">
          <li style="list-style:disc">무료체험이 끝나는 {{ payDay }}에 결제가 시작되며 플랜주기별로 자동으로 갱신됩니다.
            무료체험 기간이 종료되기 7일 전에 {{ user.email }}으로 이메일을 전송하여 이를 알려드릴 예정입니다.
          </li>
          <li style="list-style:disc">무료체험기간에는 요금이 청구되지 않으며 언제든지 해지 신청이 가능합니다.
            <a href="term" target="_blank">취소 환불</a>에 관해 자세히 알아보기.
          </li>
          <li style="list-style:disc">모든 가격은 부가가치세를 제외한 것입니다. 계속 진행함으로써 본인이 만 19세 이상임을 확인하고,
            <a href="term" target="_blank">본 약관</a>에 동의함을 확인합니다.
          </li>
          <li style="list-style:disc">계속 진행하면 런치팩 서비스 약관에 동의하는 것으로 간주됩니다.
            <a href="privacy" target="_blank">개인정보처리방침</a> - 개인정보 수집 및 공유에 사용자의 데이터를 처리하는 방식이 설명되어 있습니다.
          </li>
        </ul>

        <billing-form ref="form" class="margin-top-60"></billing-form>
      </div>



      <div class="flex-center">
        <div class="flex-align" style="max-width:370px;width:100%;margin-bottom:40px">
          <button class="button is-primary body2-medium" @click="clickNext" style="width:100%;height:52px;margin-top:40px">14일 무료 체험 시작하기</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import UserAPIMixin from "../../../mixins/UserAPIMixin";
  import BillingForm from "../../module/BillingForm";

  export default {
    name: "ServiceCreateStep6",
    components: {BillingForm},
    mixins: [
      UserAPIMixin
    ],
    props: {
      order: {
        type: Object
      }
    },
    created() {
      this.getSelectorStocks();
    },
    data() {
      return {
        selectorStocks: undefined
      }
    },
    computed: {
      hostingName() {
        if (this.order.hosting.name === 'BASIC') {
          return '호스팅 ' + this.order.hosting.name;
        } else {
          return this.order.hosting.name;
        }
      },
      payDay() {
        return moment().add(14, 'days').format('YYYY.MM.DD')
      },
      vat() {
        return this.order.total_price * 1 / 11;
      }
    },
    methods: {
      clickPrev() {
        this.$emit('prev');
      },
      validate() {
        let valid = true;
        Object.keys(this.$refs.form.value).forEach(key=>{
          if(this.$refs.form.value[key] === '') {
            valid = false;
          }
        });

        return valid;
      },
      clickNext() {
        let param = this.$refs.form.value;
        param.service_key = this.order.key;
        if(this.$refs.form.selectedBill) {
         this.order.user_bill = this.$refs.form.selectedBill.id;
         this.$emit('next');
        } else {
          if(!this.validate()) {
            this.toast('카드 정보를 제대로 입력해주세요.');
            return;
          }

          this.$store.commit('setLoading', true);
          this.request.user.post('launchpack/card_bill', this.$refs.form.value).then(res=>{
            if(res.data.result === 'error') {
              this.$store.commit('setLoading', false);
              if(res.data.message.indexOf('CDAU1002')>-1) {
                this.toast(res.data.message.replace('[CDAU1002] ',''));
              } else {
                this.toast('카드 정보가 잘못 입력되었습니다.');
                Object.keys(this.$refs.form.err).forEach((key=>{
                  this.$refs.form.err[key].err = false;
                }));
                for(let item of [
                  { code: '3110', key: 'card_num' },
                  { code: '3115', key: 'pw' },
                  { code: '3119', key: 'ex' },
                  { code: '3131', key: 'birth' }
                ]) {
                  if(res.data.message.indexOf(item.code)>-1) {
                    this.$refs.form.err[item.key].err = true;
                  }
                }
              }
            } else {
              this.order.user_bill = res.data.bill_id;
              this.$emit('next');
            }
          }).catch(()=>{
            this.$store.commit('setLoading', false);
          });
        }
      },
      getSelectorStocks() {
        let url = `public/product/${this.order.order_product.product_id}/selector_stocks`
        this.$axios.post(url).then(res => {
          this.selectorStocks = res.data.stocks;
        });
      },
      optionName(option) {
        let name = '';
        if (this.selectorStocks) {
          let stock = this.findItem(this.selectorStocks, 'id', option.option_id)
          if (stock) name = stock.name;
        }
        return name;
      },
      optionPrice(option) {
        let price = 0;
        if (this.selectorStocks) {
          let stock = this.findItem(this.selectorStocks, 'id', option.option_id)
          if (stock) {
            price = stock.price;
          }
        }
        return price;
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  @media (min-width:1025px)
    .v-mobile
      display none
  @media (max-width:1024px)
    .step-container
      background-color #FAFAFA
      height 100%
      padding-top 40px
      padding-left 16px
      padding-right 16px

  a
    color #08f
    text-decoration underline

  .box-account
    margin-top 60px
    padding 16px 28px
    background-color $gray4

  .box-account-mo
    margin-top 30px
    padding 16px
    background-color $gray4
    border-radius 8px

  .divider
    width 100%
    height 1px
    background-color $gray1
    margin 12px 0

  .divider-main
    width 100%
    height 1px
    background-color $main
    margin 12px 0

  .box-free
    background-color $sub2
    color white
    padding 20px 28px
    border-radius 8px
    display flex
    align-items center
    justify-content space-between
</style>
